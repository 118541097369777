/*jQuery(document).ready(function(){
    jQuery('.scrollable-gallery-inner-container').slick({

    });
});*/


/*document.addEventListener('DOMContentLoaded', () => {
    const videoPlayButton = document.querySelectorAll('.video-control-button');


    videoPlayButton.forEach(el => {

        let videoId = el.getAttribute('data-video');
        //console.log("1 "+videoId);
        let videoObj = document.getElementById(videoId);
        //console.dir(videoObj);



        el.addEventListener('click', event => {
            event.preventDefault();

            //console.log("2 "+videoId);

            el.classList.add('hide')
            videoObj.play();
            videoObj.setAttribute("controls","controls")




        })

        videoObj.addEventListener("pause", (e) => {
            console.log('pause');
        })
    })

})*/